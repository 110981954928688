"use client";

import React from "react";

const RegisterDiveCenterSection = () => {
  return (
    <div className="pt-12 px-10">
      <h2 className="sm:text-2xl text-xl font-semibold mb-4">Are You a Dive Center?</h2>
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
        <div>
          <h3 className="sm:text-lg text-md font-medium">Claim Your Profile</h3>
          <p className="sm:text-md text-sm text-gray-600 text-justify sm:text-left pb-1">
            Claim your profile now to start posting your job offers!
          </p>
          <a
            href="/claim-profile"
            rel="noopener noreferrer"
            className="text-atoll-600 font-semibold"
          >
            Claim your profile
          </a>
        </div>
        <div>
          <h3 className="sm:text-lg text-md text-lg font-medium">Register now</h3>
          <p className="sm:text-md text-sm text-gray-600 text-justify sm:text-left pb-1">
            Can't you find your center? Register it now!
          </p>
          <a
            href="/register-my-center"
            rel="noopener noreferrer"
            className="text-atoll-600 font-semibold"
          >
            Create a free account
          </a>
        </div>
      </div>
    </div>
  );
};

export default RegisterDiveCenterSection;
