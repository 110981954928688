"use client";

import { useCallback } from "react";
import { useRouter } from "next/navigation";
import useLoginModal from "@/app/hooks/useLoginModal";
import { SafeUser, SimplifiedUser } from "@/app/types";
import toast from "react-hot-toast";

interface WriteReviewButtonProps {
  currentUser?: SimplifiedUser | null;
  diveCenterId?: string;
  className?: string;
  canSubmitReview?: boolean;
}

const WriteReviewButton: React.FC<WriteReviewButtonProps> = ({
  currentUser,
  diveCenterId,
  className,
  canSubmitReview
}) => {
  const loginModal = useLoginModal();
  const router = useRouter();

  const onWriteReview = useCallback(async () => {
    if (!currentUser) {
      loginModal.onOpen();
      return;
    }
  
    if (!currentUser.diver?.id || currentUser.userType === "Center") {
      toast.error("Invalid user. Centers cannot write reviews.");
      return;
    }

    try {
      if (diveCenterId) {
        if (!canSubmitReview) {
          toast.error(
            "You can only write one review per year per dive center."
          );
          return;
        }
  
        router.push(`/write-review-center/${diveCenterId}`);
      } else {
        router.push("/write-review");
      }
    } catch (error) {
      console.error("Error during review submission flow:", error);
    }
  }, [currentUser, diveCenterId, loginModal, router, canSubmitReview]);
 

  return (
    <div>
      <button
        className={`px-4 py-2 bg-atoll-600 text-white rounded-md hover:bg-atoll-700 cursor-pointer ${className}`}
        onClick={onWriteReview}
      >
        Write a review
      </button>
    </div>
  );
};

export default WriteReviewButton;
